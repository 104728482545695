<template>
  <div class='summary p-2'>
    <linkCard :linkUrl='linkUrl' :imageUrl='imageUrl' class='h-100'>
      <template slot='title'>
        <h4>{{ post.title }}</h4>
      </template>
      <template slot='body'>
        <div v-if='post.intro !== null'>{{ post.intro }}</div>
        <p v-else class='body-limit-length' v-html="post.body"></p>
      </template>
      <template slot='footer'>
        <div class='text-right pl-2 pr-2'>
          <span class='small' v-if='viewingMyPosts'>
            <template v-if="post.public">
              <strong class='text-success'>Public</strong>
            </template>
            <template v-else>
              <strong class='text-warning'>Unlisted</strong>
            </template>
            -
          </span>
          <em class='small'>Published: {{ datePublished }}</em>
        </div>
      </template>
    </linkCard>
  </div>
</template>

<script>
import LinkCard from '../LinkCard.vue'
import { mapState } from 'vuex'
export default {
  props: [
    'post',
    'handle'
  ],
  components: {
    LinkCard
  },
  computed: {
    ...mapState('account', ['user']),
    date: function () {
      return new Date(this.post.created)
    },
    linkUrl: function () {
      return `/post/${this.date.getFullYear()}/${this.date.getMonth() + 1}/${this.post.postName}`
    },
    datePublished: function () {
      return this.date.toLocaleDateString()
    },
    imageUrl: function () {
      if (this.post.primaryImage) {
        return this.post.primaryImage.url
      }

      return null
    },
    viewingMyPosts: function () {
      if (!this.user || !this.post.user) {
        return false
      }
      return this.post.user.handle === this.user.handle
    }
  }
}
</script>
<style lang="scss" scoped>
.body-limit-length {
  max-height: 4em;
  overflow: hidden;
}
</style>
