<template>
  <div class="container mt-5 mb-5">
    <div class='row'>
      <div class='col'>
        <div class='row'>
          <div class='col pt-5'>
            <h1>Welcome to Philo's <em class='text-muted'>Blog</em></h1>
          </div>
        </div>
        <div class='row'>
          <div class='col text-muted'>
            <p>
              A place to edit and share ideas
            </p>
          </div>
        </div>
        <div class='row mt-4'>
          <div class='col '>
            <p>
              To get started, please register and create your first post. To share, just distribute the link, simple as that.
            </p>
          </div>
        </div>
        <div class='row mt-2'>
          <div class='col '>
            <p>
              API access is available so you can import the blog into your website, please contact us for information.
            </p>
          </div>
        </div>
      </div>
      <div class='col-4 pt-5'>
        <img src='../assets/images/powerlines.jpg' class='img-fluid' />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Home',
  metaInfo: {
    title: 'Welcome'
  },
  components: {
  },
  data: function () {
    return {
      posts: []
    }
  },
  methods: {
    ...mapActions('blog', ['getGlobalPosts'])
  },
  mounted: function () {
    // this.getGlobalPosts()
    //   .then((posts) => {
    //     this.posts = posts
    //   })
  }
}
</script>
<style lang="scss" scoped>

</style>
