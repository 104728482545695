const apiUrl = process.env.VUE_APP_API_URL

const imageUrl = function (id) {
  return `${apiUrl}/image/get/${id}`
}

const uploadPostPrimaryImageUrl = function (postName) {
  return `${apiUrl}/blog/uploadprimaryimage/${postName}`
}

const uploadUserImageUrl = function () {
  return `${apiUrl}/users/uploadprofileimage`
}

export {
  imageUrl,
  uploadPostPrimaryImageUrl,
  uploadUserImageUrl
}
