import { userService } from '../_services'
import router from '../router'

const user = JSON.parse(localStorage.getItem('philo-blog-user'))
const state = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null }

const actions = {
  login ({ dispatch, commit }, { emailaddress, password }) {
    commit('loginRequest', { emailaddress })

    return userService.login(emailaddress, password)
      .then(
        user => {
          commit('loginSuccess', user)
          router.push(`/user/${user.handle}`)
        },
        error => {
          commit('loginFailure', error)
          dispatch('alert/error', error, { root: true })
          return error
        }
      )
  },
  logout ({ commit }) {
    userService.logout()
    commit('logout')
  },
  profileUpdated ({ commit }, { pictureId }) {
    return userService.profileUpdated({
      picture: pictureId
    })
      .then(
        user => {
          commit('profileUpdated', user)
        }
      )
  },
  register ({ dispatch, commit }, user) {
    commit('registerRequest', user)

    userService.register(user)
      .then(
        user => {
          commit('registerSuccess', user)
          router.push('/login')
          setTimeout(() => {
            // display success message after route change completes
            dispatch('alert/success', 'Registration successful', { root: true })
          })
        },
        error => {
          commit('registerFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  checkHandle (para, handle) {
    return userService.checkHandle(handle)
  }
}

const mutations = {
  loginRequest (state, user) {
    state.status = { loggingIn: true }
    state.user = user
  },
  loginSuccess (state, user) {
    state.status = { loggedIn: true }
    state.user = user
  },
  loginFailure (state) {
    state.status = {}
    state.user = null
  },
  logout (state) {
    state.status = {}
    state.user = null
  },
  registerRequest (state) {
    state.status = { registering: true }
  },
  registerSuccess (state) {
    state.status = {}
  },
  registerFailure (state) {
    state.status = {}
  },
  profileUpdated (state, user) {
    state.user = user
  }

}

export const account = {
  namespaced: true,
  state,
  actions,
  mutations
}
