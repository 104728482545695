<template>
  <header v-bind:class="{'pinned': scrolledDown}">
    <div class='container' >
      <div class='row'>
        <div class='col-10 col-sm-9 col-md-6'>
          <a href='/'><span class='brand'><span>φ</span>&nbsp;Philo</span><em class='small'> blog</em></a>
        </div>
        <div class='col-2 col-sm-3 col-md-6 text-right'>
          <div class='narrow'>
            <svg
              id='mobileMenuBars'
              v-on:click='toggleMenuOpen()'
              v-on:focus='toggleMenuOpen(true)'
              v-on:blur='toggleMenuOpen(false)'
              aria-hidden="true" data-prefix="far"
              data-icon="bars" role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512" class="svg-inline--fa fa-bars fa-w-14 fa-3x">
              <path fill="currentColor" d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z" class=""></path>
            </svg>
          </div>
          <ul class='wide' v-bind:class="{'showNarrow' : wideMenuOpen}">
            <template v-if='!status.loggedIn'>
              <li><router-link to="/login">Login</router-link></li>
              <li><router-link to="/register">Register</router-link></li>
            </template>
            <template v-else>
              <li><router-link to="/newpost">+ New Post</router-link></li>
              <li><router-link :to="'/user/' + user.handle">My Posts</router-link></li>
              <li><router-link :to="'/account'"><span class='small'>Account</span></router-link></li>
              <li><router-link to="/login"><span class='small'>Logout</span></router-link></li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      scrolledDown: false,
      wideMenuOpen: false
    }
  },
  mounted () {
    document.addEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll () {
      this.scrolledDown = window.scrollY >= 50
      this.toggleMenuOpen(false)
    },
    toggleMenuOpen (tog) {
      const opn = typeof (tog) === 'undefined' ? !this.wideMenuOpen : tog
      this.$nextTick(() => {
        this.wideMenuOpen = opn
      })
    }
  },
  computed: {
    ...mapState('account', ['status', 'user'])
  },
  destroyed () {
    document.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style scoped lang='scss'>
a:hover {
  text-decoration: none;
  color: #004E5C;
}
header.pinned {
  background-color: #004E5C;
  height: 53px;
  border-bottom: none;
  .brand {
    color: white;
  }

  ul li {
    a,
    a:visited,
    a:hover {
      color: white;
    }
  }
}
header {
  color: #004E5C;
  font-size: 2rem;
  height: 70px;
  border-bottom: 1px solid rgba(220,220,220,.2);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vW;
  transition: background-color 0.3s ease-in;
  transition: height 0.3s ease-in;

  .narrow {
    display: none;
    svg {
      color: #004E5C;
      height: 2rem;
      cursor: pointer;
    }
    svg:focus {
      outline: none;
    }
  }
  .brand {
    span {
      line-height: 1rem;
      font-size: 3.5rem;
      font-weight: bold;
    }
  }

  ul li {
    display: inline;
    padding: 1em;
    font-size: 1rem;
    a,
    a:visited,
    a:hover {
      color: #004E5C;
      text-decoration: none;
    }
  }
}
header {
  @media screen and (max-width: 768px) {
    .narrow {
      display: block;
    }
    .wide {
      display: none;
    }
    .wide.showNarrow {
      display: block;
      background-color: rgba(0, 78, 92, 0.88);
      width: 100vW;
      position: fixed;
      padding-right: 5rem;
      left: 0;
      li {
        display: block;
        a ,
        a:visited,
        a:hover {
          color: white;
        }
      }
    }
  }
}
</style>
