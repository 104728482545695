const state = {
  loading: 0,
  isLoading: false
}

const actions = {
  addLoading ({ commit }) {
    commit('addLoading')
  },
  loadingComplete ({ commit }) {
    commit('removeLoading')
  }
}

const mutations = {
  addLoading (state) {
    state.loading++

    if (state.loading > 0) {
      state.isLoading = true
    }
  },
  removeLoading (state) {
    state.loading--

    if (state.loading <= 0) {
      state.isLoading = false
    }
  }
}

export const core = {
  namespaced: true,
  state,
  actions,
  mutations
}
