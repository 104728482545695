<template>
  <div class="container mt-5 mb-5">
    <div class='row'>
      <div class='col pt-5'>
        <h1>
          Lets Create a new Post
        </h1>
      </div>
    </div>
    <div class='row'>
      <div class='col text-muted'>
        <p>
          Just fill in the details then save. You can always come back later to make changes.
        </p>
      </div>
    </div>
    <div class='row'>
      <div class='col'>
        <editor
          :post="{
            title: '',
            classifications: [],
            keywords: [],
            body: ''
          }"
          :postName="''"
          v-on:post-saved='createPost'>
        </editor>
      </div>
    </div>
  </div>
</template>
<script>
import editor from '../components/post/Edit.vue'
import { mapActions, mapState } from 'vuex'

export default {
  data: function () {
    return {
    }
  },
  components: {
    editor
  },
  computed: {
    ...mapState('account', ['status'])
  },
  methods: {
    ...mapActions('blog', ['newPost']),
    createPost: function (saveArgs) {
      this.newPost(saveArgs)
    }
  },
  beforeMount: function () {
    if (!this.status.loggedIn) {
      window.location = '/'
    }
  }
}
</script>
<style lang="scss" scoped>
.postEditor {
  height: 100vH;
  border: 1px solid gray;
  padding: 0.5em;
}
</style>
