<template>
  <div class="container mt-5 mb-5">
    <div class='row' >
      <div class='col'>
        <h1>{{ post.title }}</h1>
      </div>
      <div class='col-2 align-self-center text-uppercase'>
        <span>{{ postStatusText }}</span>
      </div>
    </div>
    <div class='row mt-4' >
      <div class='col-4' v-if='editMode || post.primaryImage != null'>
        <div v-if='!canEdit'>
          <img :src='post.primaryImage.url' v-if='post.primaryImage != null' class='img-fluid' />
        </div>
        <div v-if='!editMode'>
          <img :src='post.primaryImage.url' v-if='post.primaryImage != null' class='img-fluid' />
        </div>
        <imageUpload
          v-else
          @upload-success="uploadImageSuccess"
          @before-remove="beforeRemove"
          @edit-image="editImage"
          @data-change="dataChange"
          @mark-is-primary='primaryChanged'
          :data-images="images">
        </imageUpload>
        <!-- <imageViewOrEdit v-else
          :editing='editMode'
          :imageSrc='imageSrc'
          :uploadUrl='uploadUrl'
          @image-updated='imageUpdated'></imageViewOrEdit> -->
      </div>
      <div class='col text-right' v-if='canEdit'>
        <editOptions :post='post' v-on:action-performed='actionPerformed'></editOptions>
      </div>
    </div>
    <div class='row'>
      <div class='col'>
      </div>
    </div>
    <div class='row' v-if='!editMode'>
      <div class='col'>
        <div class='mt-5' v-html='postBody'>
        </div>
      </div>
    </div>
    <editor v-else
      :postName='postName'
      :post='post'
      v-on:post-saved='savePost'></editor>
  </div>
</template>
<script>
import editor from '@/components/post/Edit.vue'
import editOptions from '@/components/post/EditOptions.vue'
import { mapActions, mapState } from 'vuex'
import { uploadPostPrimaryImageUrl, imageUrl } from '../_helpers'
// import imageViewOrEdit from '@/components/ImageOrUpload.vue'
import imageUpload from '@/components/MultiImageUpload.vue'
import { blogService } from '@/_services/blog.service'

export default {
  props: [
    'year',
    'month',
    'postName'
  ],
  components: {
    editor,
    editOptions,
    // imageViewOrEdit,
    imageUpload
  },
  metaInfo () {
    return {
      title: this.postTitle
    }
  },
  data () {
    return {
      post: {},
      editMode: false
    }
  },
  computed: {
    ...mapState('account', ['status', 'user']),
    postTitle () {
      if (this.post && this.post.title) {
        return this.post.title
      }
      return 'View Post'
    },
    postBody: function () {
      return this.post.body
    },
    canEdit: function () {
      if (!this.user || !this.post.user) {
        return false
      }
      return this.post.user.handle === this.user.handle
    },
    postStatusText: function () {
      if (!this.post.public) {
        return '- unlisted'
      }
      if (this.canEdit) {
        return '- public'
      }
      return ''
    },
    imageSrc: function () {
      return this.post.primaryImageUrl ? this.post.primaryImageUrl
        : (this.post.imageId === null ? null : imageUrl(this.post.imageId))
    },
    uploadUrl: function () {
      return uploadPostPrimaryImageUrl(this.post.postName)
    },
    images () {
      const toReturn = []
      if (!this.post) {
        return toReturn
      }

      if (this.post.primaryImage) {
        toReturn.push({
          path: this.post.primaryImage.url,
          id: this.post.primaryImage.id
        })
      }

      this.post.altImages
        .forEach(altImage => {
          toReturn.push({
            path: altImage.url,
            id: altImage.id
          })
        })

      return toReturn
    }
  },
  methods: {
    ...mapActions('blog', ['getIndividualPost', 'updatePost', 'addImageToPost']),
    toggleEdit: function () {

    },
    actionPerformed: function (paras) {
      if (paras.action === 'edit') {
        this.editMode = !this.editMode
      } else if (paras.action === 'public-toggle') {
        this.post.public = paras.result
      }
    },
    savePost: function (saveArgs) {
      this.updatePost(saveArgs)
        .then((post) => {
          this.post = post
          this.editMode = false
        })
    },
    imageUpdated: function (newId) {
      this.post.imageId = newId
    },
    uploadImageSuccess (formData /*, index, fileList */) {
      const toUpload = formData.get('file')

      if (toUpload) {
        blogService.addImageToPost(
          {
            postName: this.post.postName,
            image: formData.get('file')
          }
        )
          .then((res) => {
            this.post.images.push(res.image)
          })
      }
    },
    beforeRemove (index, done, fileList) {
      blogService.deletePostImage(
        {
          postName: this.post.postName,
          imageId: fileList[index].id
        }
      )
        .then((post) => {
          this.post = post
          done()
        })
    },
    primaryChanged (index, fileList) {
      blogService.setPrimaryImage(
        {
          postName: this.post.postName,
          imageId: fileList[index].id
        }
      )
        .then((res) => {
          if (res.primaryId) {
            this.post.imageId = res.post.imageId
            this.post.images = res.post.images
          }
        })
    },
    editImage () {
    },
    dataChange () {
    }
  },
  mounted: function () {
    this.getIndividualPost({
      postName: this.postName
    })
      .then((post) => {
        this.post = post
      })
  }
}
</script>
<style lang="scss" scoped>
.postEditor {
  height: 70vH;
}
</style>
