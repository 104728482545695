<template>
  <div class='row justify-content-end'>
    <div class='col small text-right main-menu align-self-center'>
      <button class='btn btn-primary' @click.prevent="actionPerformed('edit')">Edit Post</button>

      <span class='ml-3'>Advanced Options&nbsp;<i class="fas fa-chevron-down"></i></span>
      <div class='context-menu pr-3 border-left border-right border-bottom'>
        <ul class='mt-3 text-left'>
          <li><a @click.prevent="makePublic">Make {{ post.public ? 'Unlisted' : 'Public' }}</a></li>
          <li><a @click.prevent="deleteThisPost">Delete</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  props: [
    'post'
  ],
  data: function () {
    return {
      showImageUpload: false
    }
  },
  methods: {
    ...mapActions('blog', ['togglePublic', 'deletePost']),
    actionPerformed: function (action, result) {
      this.$emit('action-performed', {
        action: action,
        result: result
      })
    },
    makePublic: function () {
      this.togglePublic({
        postName: this.post.postName,
        toggle: !this.post.public
      })
        .then((publicRes) => {
          this.actionPerformed('public-toggle', publicRes)
        })
    },
    deleteThisPost () {
      this.deletePost({
        postName: this.post.postName
      })
        .then(() => {
          this.$router.push({ name: 'userposts', params: { handle: this.post.user.handle } })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.main-menu {
  cursor: pointer;
  .context-menu {
    z-index: 5;
    position: absolute;
    right: 0;
    display: none;
  }
}
.main-menu:focus .context-menu,
.main-menu:hover .context-menu {
  display: block;
}

a {
  cursor: pointer;
}
</style>
