<template>
  <div id="app">
    <div class="pt-5">
      <toast position="se"></toast>
      <siteheader></siteheader>
      <div class='pageContent'>
        <router-view class='page-fill'></router-view>
      </div>
      <sitefooter></sitefooter>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vuex-toast'
import header from './components/Header.vue'
import footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Toast,
    siteheader: header,
    sitefooter: footer
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Home',
    // all titles will be injected into this template
    titleTemplate: '%s | Philo - The Blog'
  }
}
</script>
<style lang="scss" scoped>
// .pageContent {
//   min-height: calc(100vH - 413px);
// }
</style>

<style lang="scss">
  @import 'assets/scss/style';
</style>
