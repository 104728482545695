const authHeader = function () {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem('philo-blog-user'))

  if (user && user.token) {
    return { Authorization: `Bearer ${user.token}` }
  }
  return {}
}

export { authHeader }
