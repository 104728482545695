<template>
  <div class="container mt-5 mb-5">
    <div class='row'>
      <div class='col'>
        <h1>
          Posts by {{ handle }}
        </h1>
      </div>
    </div>
    <div class='row mt-4' >
      <div class='col d-flex flex-wrap'>
        <postSummary :post='post' :handle='handle' v-for="post in postsToShow" v-bind:key='post.postname'
          class='limit-width' />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import postSummary from '../components/post/Summary.vue'

export default {
  props: [
    'handle'
  ],
  components: {
    postSummary
  },
  data: function () {
    return {
      posts: []
    }
  },
  computed: {
    ...mapState('account', ['status', 'user']),
    ...mapState('blog', ['userPosts']),
    postsToShow: function () {
      return this.userPosts[this.handle]
    }
  },
  methods: {
    ...mapActions('blog', ['getUserPosts'])
  },
  mounted: function () {
    this.getUserPosts({
      userHandle: this.handle
    })
      .then((posts) => {
        this.posts = posts
      })
  }
}
</script>
<style lang="scss" scoped>
.limit-width {
  max-width: 50%;
}
@media screen and (max-width: 991px) {
  .limit-width {
    max-width:100%;
  }
}
</style>
