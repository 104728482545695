<template>
  <div class="container mt-5 mb-5">
    <div class='row mt-5'>
      <div class='col col-md-8 col-lg-9'>
        <h1>Login</h1>
        <p class='text-muted'>Welcome back to your creative platform</p>
        <div class='pt-0 pl-5 hidemobile'>
          <img src='../assets/images/coding2.jpg' class='img-fluid img-reduced' />
        </div>
      </div>
      <div class='col col-md-4 col-lg-3'>
        <div class='row border pt-5 pb-5 pl-3 pr-3'>
          <form @submit.prevent='doLogin'>
            <div class="form-group">
              <label for="exampleInputEmail1">Email Address</label>
              <input type="email" v-model="emailaddress" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" required>
              <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Password</label>
              <input type="password" v-model="password"  class="form-control" id="exampleInputPassword1" placeholder="Password" required>
            </div>
            <div class='text-right'>
              <button type="submit" class="btn btn-primary ">Submit</button>
            </div>
          </form>
          <div v-if='showError' class='text-danger'>
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      emailaddress: '',
      password: '',
      submitted: false,
      showError: false,
      errorMessage: ''
    }
  },
  computed: {
    ...mapState('account', ['status'])
  },
  created () {
    // reset login status
    this.logout()
  },
  methods: {
    ...mapActions('account', ['login', 'logout']),
    doLogin: function (args) {
      if (args.srcElement.checkValidity()) {
        const { emailaddress, password } = this
        this.login({ emailaddress, password })
          .then((res) => {
            this.showError = true
            this.errorMessage = res
          })
      } else {
        args.srcElement.reportValidity()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.img-reduced {
  max-width: 400px;
}
@media screen and (max-width: 767px) {
  .hidemobile {
    display: none;
  }
}
</style>
