import MediumEditor from 'medium-editor'

var CLASS_DRAG_OVER = 'medium-editor-dragover'

function clearClassNames (element) {
  var editable = MediumEditor.util.getContainerEditorElement(element)
  var existing = Array.prototype.slice.call(editable.parentElement.querySelectorAll('.' + CLASS_DRAG_OVER))

  existing.forEach(function (el) {
    el.classList.remove(CLASS_DRAG_OVER)
  })
}

var FileDragging = function ({ uploadPromise, getImageUrlFunc, uploadParams }) {
  if (!uploadPromise || !getImageUrlFunc || !uploadParams) {
    return {}
  }

  return MediumEditor.Extension.extend({
    name: 'fileDragging',

    allowedTypes: ['image'],

    init: function () {
      MediumEditor.Extension.prototype.init.apply(this, arguments)

      this.subscribe('editableDrag', this.handleDrag.bind(this))
      this.subscribe('editableDrop', this.handleDrop.bind(this))
    },

    handleDrag: function (event) {
      event.preventDefault()
      event.dataTransfer.dropEffect = 'copy'

      var target = event.target.classList ? event.target : event.target.parentElement

      // Ensure the class gets removed from anything that had it before
      clearClassNames(target)

      if (event.type === 'dragover') {
        target.classList.add(CLASS_DRAG_OVER)
      }
    },

    handleDrop: function (event) {
      // Prevent file from opening in the current window
      event.preventDefault()
      event.stopPropagation()
      // Select the dropping target, and set the selection to the end of the target
      // https://github.com/yabwe/medium-editor/issues/980
      this.base.selectElement(event.target)
      var selection = this.base.exportSelection()
      selection.start = selection.end
      this.base.importSelection(selection)
      // IE9 does not support the File API, so prevent file from opening in the window
      // but also don't try to actually get the file
      if (event.dataTransfer.files) {
        Array.prototype.slice.call(event.dataTransfer.files).forEach(function (file) {
          if (this.isAllowedFile(file)) {
            if (file.type.match('image')) {
              this.insertImageFile(file)
            }
          }
        }, this)
      }

      // Make sure we remove our class from everything
      clearClassNames(event.target)
    },

    isAllowedFile: function (file) {
      return this.allowedTypes.some(function (fileType) {
        return !!file.type.match(fileType)
      })
    },

    insertImageFile: function (file) {
      var para = JSON.parse(JSON.stringify(uploadParams))
      para.image = file
      uploadPromise(para)
        .then(res => {
          if (res !== undefined) {
            var addImageElement = this.document.createElement('img')
            addImageElement.src = getImageUrlFunc(res)
            addImageElement.className = 'img-fluid'
            MediumEditor.util.insertHTMLCommand(this.document, addImageElement.outerHTML)
          }
        })
    }
  })
}

export { FileDragging }
