import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import NewPost from '../views/NewPost.vue'
import UserPosts from '../views/UserPosts.vue'
import ViewPost from '../views/ViewPost.vue'
import MyAccount from '../views/accounts/MyAccount.vue'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/newpost',
      name: 'newpost',
      component: NewPost
    },
    {
      path: '/account',
      name: 'account',
      component: MyAccount
    },
    {
      path: '/user/:handle',
      name: 'userposts',
      component: UserPosts,
      props: true
    },
    {
      path: '/post/:year/:month/:postName',
      name: 'viewPost',
      component: ViewPost,
      props: true
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  ]
})

export default router
