import axios from 'axios'
import { authHeader } from '@/_helpers/auth-header'
import store from '@/store'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'

const apiUrl = process.env.VUE_APP_API_URL

export const CoreApi = {
  Get,
  GetFile,
  PostJson,
  PostForm,
  PutJson,
  Delete
}

function Get (path, successToast) {
  store.dispatch('core/addLoading')
  return HandleResponse(axios.get(`${apiUrl}${path}`,
    {
      headers: { ...authHeader() }
    }), successToast)
}

function GetFile (path, successToast, fileName = 'result') {
  store.dispatch('core/addLoading')
  return HandleResponse(axios.get(`${apiUrl}${path}`,
    {
      headers: { ...authHeader() },
      responseType: 'blob'
    }), successToast)
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    })
}

function PostJson (path, bodyobject, successToast) {
  store.dispatch('core/addLoading')
  return HandleResponse(axios.post(`${apiUrl}${path}`, bodyobject,
    {
      headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }), successToast)
}

function PostForm (path, form, successToast) {
  store.dispatch('core/addLoading')
  return HandleResponse(
    axios.post(`${apiUrl}${path}`, form,
      {
        headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' }
      }), successToast)
}

function PutJson (path, bodyobject, successToast) {
  store.dispatch('core/addLoading')
  return HandleResponse(axios.put(`${apiUrl}${path}`, bodyobject,
    {
      headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }), successToast)
}

function Delete (path, successToast) {
  store.dispatch('core/addLoading')
  return HandleResponse(axios.delete(`${apiUrl}${path}`,
    {
      headers: { ...authHeader() }
    }), successToast)
}

function HandleResponse (axiosRequest, successToast) {
  return axiosRequest
    .then(response => {
      if (successToast) {
        store.dispatch(ADD_TOAST_MESSAGE, {
          text: successToast,
          type: 'success',
          dismissAfter: 1000
        })
      }

      return {
        success: true,
        data: response.data
      }
    },
    reason => {
      var response = {
        success: false,
        status: null,
        message: 'Request Failed - Please Try Again',
        data: {}
      }

      if (reason.response) {
        response.status = reason.response.status

        if (response.status === 401) {
          store.dispatch('account/logout')
          response.message = 'Your Login has Expired, Please Login Again'
        } else if (response.status === 500) {
          response.message = 'A Server Error Occoured. Please try again, if the problem persists please contact support'
        }
        response.data = reason.response.data

        if (reason.response.data && reason.response.data.message) {
          response.message = reason.response.data.message
        }
      }

      store.dispatch(ADD_TOAST_MESSAGE, {
        text: response.message,
        type: 'danger'
      })

      return response
    })
    .catch(err => {
      var message = 'Critical Request Error - Please Contact Support'

      console.log(err)
      store.dispatch(ADD_TOAST_MESSAGE, {
        text: message,
        type: 'danger'
      })

      return {
        success: false,
        status: null,
        message: message,
        data: {}
      }
    })
    .finally(() => {
      store.dispatch('core/loadingComplete')
    })
}
