import Vue from 'vue'
import Vuex from 'vuex'

import { core } from './core.module'
import { account } from './account.module'
import { users } from './users.module'
import { blog } from './blog.module'

import { createModule } from 'vuex-toast'
import 'vuex-toast/dist/vuex-toast.css'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    core,
    account,
    users,
    blog,
    toast: createModule()
  }
})
