<template>
  <div class="container mt-5 mb-5">
    <div class='row mt-5'>
      <div class='col'>
        <h1>Hi {{ user.handle }}</h1>
        <span class='text-muted'>Manage your account below</span>
      </div>
    </div>
    <div class='row mt-3'>
      <div class='col-6'>
        <label for="basic-url">Account Image:</label>
        <imageOrUpload
          :editing='editMode'
          :imageSrc='imageSrc'
          :uploadUrl='uploadUrl'
          @image-updated='imageUpdated'>
        </imageOrUpload>
      </div>
      <div class='col-6'>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import imageOrUpload from '../../components/ImageOrUpload.vue'
import { uploadUserImageUrl, imageUrl } from '../../_helpers'
export default {
  name: 'myAccount',
  components: {
    imageOrUpload: imageOrUpload
  },
  data: function () {
    return {
      editMode: true
    }
  },
  computed: {
    ...mapState('account', ['user']),
    uploadUrl: function () {
      return uploadUserImageUrl()
    },
    imageSrc: function () {
      return this.user.picture === null ? null : imageUrl(this.user.picture)
    }
  },
  methods: {
    ...mapActions('account', ['profileUpdated']),
    imageUpdated: function (imageId) {
      this.profileUpdated({
        pictureId: imageId
      })
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
