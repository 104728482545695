import { CoreApi } from '@/_services/core.api.service'

export const blogService = {
  newPost,
  getUserPosts,
  getIndividualPost,
  updatePost,
  togglePublic,
  addImageToPost,
  deletePostImage,
  setPrimaryImage,
  deletePost
}

function newPost (title, classifications, keywords, intro, body) {
  return CoreApi.PostJson('/blog/create',
    {
      title,
      classifications,
      keywords,
      intro,
      body
    },
    'Post Created')
    .then(res => {
      return res.data
    })
}

function getUserPosts (userHandle) {
  return CoreApi.Get(`/blog/getall/${userHandle}?t=${Date.now()}`)
    .then(res => {
      return res.data
    })
}

function getIndividualPost (postName) {
  return CoreApi.Get(`/blog/getpost/${postName}?t=${Date.now()}`)
    .then(res => {
      return res.data
    })
}

function updatePost ({ postName, title, classifications, keywords, intro, body }) {
  return CoreApi.PutJson(`/blog/update/${postName}`,
    {
      title,
      classifications,
      keywords,
      intro,
      body
    },
    'Post Updated')
    .then(res => {
      return res.data
    })
}

function deletePost ({ postName }) {
  return CoreApi.Delete(`/blog/delete/${postName}`,
    {
      postName
    },
    'Post Removed')
    .then(res => {
      return res.data
    })
}

function togglePublic ({ postName, toggle }) {
  return CoreApi.PostJson(`/blog/togglepublic/${postName}/${toggle}`,
    { },
    toggle ? 'Post Public' : 'Post Hidden')
    .then(res => {
      return res.data.isPublic
    })
}

function addImageToPost ({ postName, image }) {
  const form = new FormData()
  form.append('file', new Blob([image]))

  return CoreApi.PostForm(`/blog/addimage/${postName}`, form)
    .then(res => {
      return res.data
    })
}

function deletePostImage ({ postName, imageId }) {
  return CoreApi.Delete(`/blog/image/${postName}/${imageId}`)
    .then(res => {
      return res.data
    })
}

function setPrimaryImage ({ postName, imageId }) {
  return CoreApi.PostJson(`/blog/setPrimaryImage/${postName}/${imageId}`)
    .then(res => {
      return res.data
    })
}
