<template>
  <div class='row no-gutters'>
    <div class='col-10'>
      <div v-if='!editing || !localEditMode' class='text-center'>
        <img v-if='imageSrc != null' :src='imageSrc' class='img-fluid' />
        <span v-else>Use edit to upload an image</span>
      </div>
      <vueDropzone v-else
        id='primayImageUpload'
        ref='primaryImageUpload'
        :options="dropzoneOptions"
        @vdropzone-file-added="vfileAdded"
        @vdropzone-success='vfileUploadSuccess'>
      </vueDropzone>
    </div>
    <div class='col-2 pl-1'>
      <div v-if='editing'>
        <i v-if='!localEditMode' class="fas fa-edit" @click.prevent='localEditMode=true'></i>
        <i v-else class="fas fa-times" @click.prevent='localEditMode=false'></i>
      </div>
    </div>
  </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { authHeader } from '../_helpers'

export default {
  props: [
    'editing',
    'imageSrc',
    'uploadUrl'
  ],
  components: {
    vueDropzone: vue2Dropzone
  },
  data: function () {
    return {
      localEditMode: false,
      dropzoneOptions: {
        url: this.uploadUrl,
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: authHeader(),
        // addRemoveLinks: true,
        uploadMultiple: false
      }
    }
  },
  computed: {
  },
  methods: {
    vfileAdded: function () {
      var filesUploaded = this.$refs.primaryImageUpload.getAcceptedFiles()

      if (filesUploaded.length >= 1) {
        this.$refs.primaryImageUpload.removeFile(filesUploaded[0])
      }
    },
    vfileUploadSuccess: function (file, response) {
      if (response.imageId) {
        this.$emit('image-updated', response.imageId)
        this.localEditMode = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.vue-dropzone {
  border: none;
}
</style>
