<template>
  <div class="container mt-5 mb-5">
    <div class='row'>
      <div class='col'>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Title</span>
          </div>
          <input type="text" v-model="post.title" class="form-control" placeholder="Post Title" aria-label="Title">
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='col'>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Category</span>
          </div>
          <input type="text" v-model="classifications" class="form-control" placeholder="Category" aria-label="Category">
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='col'>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Keywords</span>
          </div>
          <input type="text" v-model="keywords" class="form-control" placeholder="Keywords" aria-label="Keywords">
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='col'>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Introduction</span>
          </div>
          <input type="text" v-model="post.intro" class="form-control" placeholder="Introduction" aria-label="Keywords">
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='col'>
        <div class='postEditor' ref='editor'>
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='col text-right mt-3'>
        <button class='btn btn-primary' @click.prevent='savePost'>Save</button>
      </div>
    </div>
  </div>
</template>
<script>
import Editor from 'medium-editor'
import { FileDragging } from '../../_helpers'
import { mapActions } from 'vuex'
require('../../../node_modules/medium-editor/dist/css/medium-editor.css')
require('../../../node_modules/medium-editor/dist/css/themes/bootstrap.css')

export default {
  props: [
    'postName',
    'post'
  ],
  data: function () {
    return {
      classifications: '',
      keywords: ''
    }
  },
  mounted: function () {
    Editor.extensions.fileDragging = FileDragging({
      uploadPromise: this.addImageToPost,
      getImageUrlFunc: (img) => {
        return img.url
      },
      uploadParams: {
        postName: this.postName
      }
    })
    this.editor = new Editor('.postEditor', {
      toolbar: {
        imageDragging: true,
        buttons: [
          'bold',
          'italic',
          'underline',
          'anchor',
          'h2',
          'h3',
          'h4',
          'orderedlist',
          'unorderedlist'
        ]
      },
      extensions: {
        fileDragging: FileDragging
      }
    })
    this.editor.setContent(this.post.body, 0)

    this.classifications = this.post.classifications.join(',')
    this.keywords = this.post.keywords.join(',')
  },
  methods: {
    ...mapActions('blog', ['addImageToPost']),
    savePost: function () {
      this.$emit('post-saved', {
        postName: this.postName,
        classifications: this.classifications.split(','),
        keywords: this.keywords.split(','),
        title: this.post.title,
        intro: this.post.intro,
        body: this.editor.getContent(0)
      })
    }
  },
  destroyed: function () {
    this.editor.destroy()
  }
}
</script>
<style lang="scss" scoped>
.postEditor {
  min-height: 50vH;
  border: 1px solid gray;
  padding: 0.5em;
}
</style>
