<template>
  <div class='card-outer'>
    <div class="card flex-row">
      <div class="card-header border-0" v-if='imageUrl !== null'>
        <img :src="imageUrl" alt="">
      </div>
      <div class="card-block pt-4 pl-4 pr-4 pb-0">
        <div class='card-title'>
          <a :href='linkUrl'>
            <slot name='title'></slot>
          </a>
        </div>
        <p class="card-text">
          <slot name='body'></slot>
          <a :href='linkUrl' class='read-more'>Read More..</a>
        </p>
      </div>
      <div class='card-keywords p-2 small text-center' v-if='!!$slots.keywords'>
        <slot name='keywords'></slot>
      </div>
    </div>
    <div class='footer align-bottom' v-if='!!$slots.footer'>
      <slot name='footer'></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'linkUrl',
    'imageUrl'
  ]
}
</script>
<style scoped lang="scss">

.card-outer {
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.05);
}
.card {
  height: calc(100% - 30px);
  border: none;
  .card-header {
    background: none;
    display: flex;
    min-width: 100px;
    img {
      transition: all 0.3s ease-in-out;
      width: 100%;
      max-width: 200px;
      display: block;
      align-self: center;
    }
  }
  .card-block {
    transition: transform 0.3s ease-in-out;
    transform: translateY(0);
    display: block;
    overflow: hidden;
    cursor: pointer;
    em {
      transition: opacity 0.3s ease-in-out;
      opacity: 1;
    }
    .card-text {
      a.read-more {
        transition: opacity 0.3s ease-in-out;
        opacity: 0;
      }
    }
  }
  .card-keywords {
    border-left: 1px rgba(80, 80,80, 0.1) solid;
    min-width: 9em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
    div {
      padding: 0.4em;
    }
  }
}
@media screen and (max-width: 991px) {
  .card-outer {
    border-top: 1px rgba(80, 80,80, 0.1) solid;
  }
}
@media screen and (max-width: 768px) {

  .card.flex-row {
    flex-direction: column !important;
  }
  .card .card-header {
    width: 100%;
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .card .card-keywords {
    border-left: 0;
    border-top: 1px rgba(80, 80,80, 0.1) solid;
    flex-direction: row;
    div {
      margin-left: 1em;
      margin-right: 1em;
    }
  }
}
.card:hover {
  img {
    transform: scale(0.8);
  }
  .card-block {
    transform: translateY(-1.5em);
    em {
      opacity: 0;
    }
    .card-text {
      a.read-more {
        opacity: 1;
      }
    }
  }
  .card-keywords {
    transform: translateY(-1.5em);
  }
}
.card .card-title a:hover {
  text-decoration: none;
}
.footer {
  max-height: 30px;
  height: 30px;
}
</style>
