<template>
  <div class="container mt-5 mb-5">
    <div class='row'>
      <div class='col col-md-8 col-lg-9'>
        <h1>Register</h1>
        <p class='text-muted'>You are steps away from your new creative platform</p>
        <div class='pt-0 pl-5 hidemobile'>
          <img src='../assets/images/coding.jpg' class='img-fluid img-reduced' />
        </div>
      </div>
      <div class='col col-md-4 col-lg-3'>
        <div  class='row border pt-5 pb-5 pl-3 pr-3'>
          <form @submit.prevent="handleSubmit">
            <div class="form-group">
              <label for="username">Handle</label>
              <div class='input-group'>
                <div class="input-group-prepend">
                  <span class="input-group-text">@</span>
                </div>
                <input type="text" v-model="user.handle" required name="handle" class="form-control" />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i v-if="handleState=='valid'" class="fas fa-check"></i>
                    <i v-else-if="handleState=='inuse'" class="fas fa-times"></i>
                    <i v-else-if="handleState=='checking'" class="fas fa-spinner"></i>
                    <i v-else class="fas fa-level-down-alt"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="email">Email Address</label>
              <input type="text" v-model="user.emailAddress" required name="email" class="form-control" />
            </div>
            <div class="form-group">
              <label htmlFor="password">Password</label>
              <input type="password" v-model="user.password" required name="password" class="form-control" />
            </div>
            <div class="form-group">
              <button class="btn btn-primary" :disabled="status.registering">Register</button>
              <img v-show="status.registering" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              <router-link to="/login" class="btn btn-link">Cancel</router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { debounce } from 'lodash'
export default {
  data () {
    return {
      handleState: 'dunno',
      user: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        username: '',
        password: '',
        handle: ''
      }
    }
  },
  computed: {
    ...mapState('account', ['status'])
  },
  methods: {
    ...mapActions('account', ['register', 'checkHandle']),
    handleSubmit (e) {
      if (e.srcElement.checkValidity() && this.handleState === 'valid') {
        this.register({
          firstName: '',
          lastName: '',
          emailAddress: this.user.emailAddress,
          username: this.user.handle,
          password: this.user.password,
          handle: this.user.handle
        })
      } else {
        e.srcElement.reportValidity()
      }
    },
    checkHandleAvailable: debounce(function () {
      var checkedHandle = this.user.handle
      if (!checkedHandle || checkedHandle === '') {
        this.handleState = 'dunno'
        return
      }
      this.handleState = 'checking'
      this.checkHandle(checkedHandle)
        .then((res) => {
          if (this.user.handle === checkedHandle) {
            if (res.isAvailable === true) {
              this.handleState = 'valid'
            } else {
              this.handleState = 'inuse'
            }
          } else {
            this.handleState = 'dunno'
          }
        })
    }, 500)
  },
  watch: {
    'user.handle': function () {
      this.handleIsAvailable = false
      this.checkHandleAvailable()
    }
  }
}
</script>
<style lang="scss" scoped>
.img-reduced {
  max-width: 400px;
}
@media screen and (max-width: 767px) {
  .hidemobile {
    display: none;
  }
}
.fa-spinner {
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}
</style>
