import router from '../router'
import { blogService } from '../_services'
import Vue from 'vue'

const state = {
  userPosts: {}
}

const actions = {
  newPost ({ dispatch }, { title, classifications, keywords, intro, body }) {
    blogService.newPost(title, classifications, keywords, intro, body)
      .then(
        post => {
          var date = new Date(post.created)
          router.push(`/post/${date.getFullYear()}/${date.getMonth() + 1}/${post.postName}`)
        },
        error => {
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getUserPosts ({ dispatch, commit }, { userHandle }) {
    blogService.getUserPosts(userHandle)
      .then(
        posts => {
          commit('userPostsLoaded', { userHandle, posts })
        },
        error => {
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getIndividualPost ({ dispatch }, { postName }) {
    return blogService.getIndividualPost(postName)
      .then(
        posts => {
          return posts
        },
        error => {
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  updatePost ({ dispatch }, { postName, title, classifications, keywords, intro, body }) {
    return blogService.updatePost({ postName, title, classifications, keywords, intro, body })
      .then(
        posts => {
          return posts
        },
        error => {
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  deletePost (e, { postName }) {
    return blogService.deletePost({ postName })
      .then(res => {
        return res
      })
  },
  togglePublic ({ dispatch }, { postName, toggle }) {
    return blogService.togglePublic({ postName, toggle })
      .then(
        isPublic => {
          return isPublic
        },
        error => {
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  addImageToPost ({ dispatch }, { postName, image }) {
    return blogService.addImageToPost({ postName, image })
      .then(
        res => {
          if (res && res.image) {
            return res.image
          }
          return undefined
        },
        error => {
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getGlobalPosts ({ dispatch }) {
    return blogService.getGlobalPosts()
      .then(
        res => {
          if (res && res.imageId) {
            return res.imageId
          }
          return undefined
        },
        error => {
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  userPostsLoaded (state, { userHandle, posts }) {
    Vue.set(state.userPosts, userHandle, posts)
  }
}

export const blog = {
  namespaced: true,
  state,
  actions,
  mutations
}
