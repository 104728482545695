<template>
  <footer>
    <div class='container'>
      <div class='row links'>
        <div class='col-md-4'>
          <h3>Contact Us</h3>
          <ul class="list-unstyled">
            <li>
              <i class="fa fa-envelope"></i>&nbsp;
              <a href="mailto:blog@philo.co.nz">blog@philo.co.nz</a>
            </li>
            <li>
              <i class="fas fa-globe-asia"></i>&nbsp;
              <a href="https://www.philo.co.nz">www.philo.co.nz</a>
            </li>
          </ul>
        </div>
        <div class='col-md-4 text-center'>
        </div>
        <div class='col-md-4 text-right'>
        </div>
      </div>
      <div class='row'>
        <div class='col text-center'>
          <span class='brand'><span>φ</span> Philo</span>
        </div>
      </div>
      <div class='row'>
        <div class='col text-center'>
          Information Technology Consultation and Development
        </div>
      </div>
      <div class='row mt-1'>
        <div class='col text-center'>
          © Philo Limited - 2019 - All rights reserved.
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
}
</script>

<style scoped lang='scss'>
footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #fafafa;
  color: #004E5C;
  ul li {
    a,
    a:visited,
    a:hover {
      text-decoration: none;
    }
  }
  .brand {
    color: #004E5C;
    font-size: 3rem;
    span {
      line-height: 1rem;
      font-size: 4.5rem;
    }
  }
  @media screen and (max-width: 768px) {
    .links .col-md-4 {
      text-align: center !important
    }
  }
}
</style>
